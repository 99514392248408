import styled from 'styled-components';
import { SearchResultsTopTabsProps } from './Search';

export const SearchWrapper = styled.div`
  margin: 80px 0 80px 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin: 112px 0 112px 0;
  }
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.xl}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SearchFilters = styled.div<{ isGrid?: boolean }>`
  flex: 0 0 280px;
  margin-right: 40px;
  @media only screen and (max-width: ${(props) => props.theme.breakpoint.xl}) {
    display: none;
  }
`;

export const SearchResults = styled.div`
  flex: 1 1 auto;
  max-width: 780px;
  margin: 0 auto;
`;

export const SearchResultsLoading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const SearchResultsTop = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.gray30};
  margin-bottom: 32px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SearchResultsTopHeadline = styled.div`
  padding: 12px 0;
`;

export const SearchResultsTopTabs = styled.ul<SearchResultsTopTabsProps>`
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 calc(100% / ${(props) => props.tabsCount});
    padding: 12px 16px;
    position: relative;
    color: ${(props) => props.theme.color.gray50};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      flex: 0 0 auto;
      justify-content: flex-start;
    }
    svg {
      margin-right: 8px;
    }
    &:hover {
      color: ${(props) => props.theme.color.gray60};
    }
    &.active {
      color: ${(props) => props.theme.color.gray90};
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: ${(props) => props.theme.color.gray90};
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
  }
`;
