import { SearchNavigationViews } from 'types/api/whiteLabel';
import { searchResultsTopTabsListType } from './Search';
import { ParsedUrlQuery } from 'querystring';

const ROOMS_MIN_DEFAULT = 1;

export const reorderTabs = (tabs: searchResultsTopTabsListType[], order: SearchNavigationViews[]) => {
  if (!order || order.length === 0) {
    return tabs;
  }

  const orderedTabs = order.map((view) => tabs.find((tab) => tab.view === view)).filter((tab) => tab !== undefined) as searchResultsTopTabsListType[];

  const filterTabs = tabs.filter((tab) => tab.view === 'filters');

  return [...Array.from(new Set(orderedTabs)), ...filterTabs];
};

export function getQueryWithDefaultValues(originalQuery: ParsedUrlQuery): ParsedUrlQuery {
  const { roomsMin, ...queriesWithoutRooms } = originalQuery;
  const queryWithRooms = { ...queriesWithoutRooms, roomsMin: roomsMin ? roomsMin : ROOMS_MIN_DEFAULT };
  return queryWithRooms as ParsedUrlQuery;
}
