import { IconDefinition, faList, faMap, faSlidersH, faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Heading } from 'legacy-ui';
import { LocationSearchParams } from '../../components/LocationSuggestionBox/types';
import SEO from '../../components/SEO';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import Page from '../../sections/common/Page';
import Filters from '../../sections/search/Filters';
import { userState } from '../../state/userState';
import { isBelowBreakpoint } from '../../styles/theme';
import { SavedSearchAPIResult, SearchAPIResult } from '../../types/api/searchTypes';
import { LanguageKey } from '../../types/common';
import { track } from '../../utils/analytics';
import { removeEmptyValues } from '../../utils/common';
import { loadFromApi } from '../../utils/http';
import { getRoute, splitLocale } from '../../utils/languages';
import { getOGImagesFromCMSData } from '../../utils/seo';
import { SearchFilters, SearchResultsTop, SearchResultsTopHeadline, SearchResultsTopTabs } from './styles';
import { SearchProps } from './index.page';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import CurrentView from './components/CurrentView';
import { getQueryWithDefaultValues, reorderTabs } from './functions';
import { useTranslation } from 'next-i18next';

export const PAGE_SIZE = 10;
const PAGE_SIZE_MAP = 9999;

export type View = 'list' | 'grid' | 'map' | 'filters';

export type searchResultsTopTabsListType = {
  view: View;
  icon: IconDefinition;
  text: string;
};

export type SearchResultsTopTabsProps = {
  tabsCount: number;
};

const Search = ({ headerData, footerData, generalData, pageData }: SearchProps) => {
  const router = useRouter();
  const { pathname, isReady, locale } = router;
  const originalQuery = router.query;
  const query = useMemo(() => getQueryWithDefaultValues(originalQuery), [originalQuery]);
  const whiteLabelConfig = useWhiteLabel();
  const [currentView, setCurrentView] = useState<View>(whiteLabelConfig?.searchNavigationViews[0] || 'grid');
  const [searchResultType, setSearchResultType] = useState<'list' | 'grid' | 'map'>(whiteLabelConfig?.searchNavigationViews[0] || 'grid');
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchAPIResult>();
  const [savedSearches, setSavedSearches] = useState<SavedSearchAPIResult[]>([]);
  const [justSaved, setJustSaved] = useState(false);
  const [pageSize, setPageSize] = useState(currentView === 'list' ? PAGE_SIZE : PAGE_SIZE_MAP);
  const { country } = splitLocale(locale as LanguageKey);
  const { user } = useAtomValue(userState);
  const { t } = useTranslation('search');
  const [totalProperties, setTotalProperties] = useState(0);

  const handeCurrentViewChange = (view: View) => {
    updateCurrentView(view);
    //avoid setting the search result type as filter view
    if (view === 'filters') {
      return;
    }
    setSearchResultType(view);
  };

  const gotoPage = useCallback(
    (page: number) => {
      const newQuery = { ...query, page };
      router.push(
        {
          pathname: getRoute(pathname, router.locale as LanguageKey),
          query: newQuery,
        },
        undefined,
        { shallow: true },
      );
    },
    [router, query, pathname],
  );

  const windowSize = useGetWindowSize();
  const isMobile = isBelowBreakpoint(windowSize?.width || 0, 'xl');

  const updateCurrentView = useCallback(
    (view: View) => {
      setCurrentView(view);
      router.push(
        {
          pathname: getRoute(pathname, router.locale as LanguageKey),
          query: { ...query, view },
        },
        undefined,
        { shallow: true },
      );
      if (view === 'filters') {
        return;
      }
      gotoPage(1);
      setPageSize(view === 'list' ? PAGE_SIZE : PAGE_SIZE_MAP);
    },
    [router, query, pathname, gotoPage],
  );

  const handleSetTotalProperties = (count: number) => {
    setTotalProperties(count);
  };

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (query.view) {
      setCurrentView(query.view as View);
    }
  }, [isReady, query.view]);

  useEffect(() => {
    const isListOrMap = (currentView === 'list' && query.view !== 'list') || (currentView === 'map' && query.view !== 'map');
    if (isListOrMap) {
      router.push(
        {
          pathname: getRoute(pathname, router.locale as LanguageKey),
          query: { ...query, view: currentView },
        },
        undefined,
        {
          shallow: true,
        },
      );
      return;
    }
    if (query.view) {
      const { view, ...rest } = query;
      router.push(
        {
          pathname: getRoute(pathname, router.locale as LanguageKey),
          query: { ...rest },
        },
        undefined,
        { shallow: true },
      );
    }
  }, [currentView]);

  useEffect(() => {
    //TODO: If the user is on the grid view, don't load the list view, the fetching of the list should be moved to the list view component
    if (currentView === 'grid' || currentView === 'filters') {
      return;
    }
    if (!isReady) {
      return;
    }
    (async () => {
      const parsedQuery = {
        page: query.page || 1,
        ...query,
        ...(query.viewportCoordinates
          ? {
              viewportCoordinates: removeEmptyValues({
                ...JSON.parse(query.viewportCoordinates as string),
                location: undefined,
              }),
            }
          : {}),
        ...(query.propertyTypes ? { propertyTypes: JSON.parse(query.propertyTypes as string) } : {}),
        ...(query.tags ? { tags: JSON.parse(query.tags as string) } : {}),
        priceMax: query.priceMax && (Number(query.priceMax) * 100).toFixed(),
        unit: query.priceMax && 'cents',
      };

      const searchPayload = {
        country,
        transactionType: 1,
        pageSize,
        ...parsedQuery,
        ownerTypeAndIds: whiteLabelConfig.companyIds.map((id) => `c.${id}`),
        waitingLists:false,
      };
      const savedSearchesPayload = {
        administrationMode: 'user',
      };
      setLoading(true);
      const searchResponse: SearchAPIResult = await loadFromApi('search', searchPayload);
      if (user) {
        const savedSearchesResponse: SavedSearchAPIResult[] = await loadFromApi('saved-search/find', savedSearchesPayload);
        setSavedSearches(savedSearchesResponse);
      }
      setLoading(false);
      setSearchResult(searchResponse);
      setTotalProperties(searchResponse?.totalProperties || 0);
      setJustSaved(false);
      window.scrollTo({ top: 0 });
    })();
  }, [originalQuery, pageSize, isReady, country, user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    (async () => {
      const payload = {
        administrationMode: 'user',
      };
      const result: SavedSearchAPIResult[] = await loadFromApi('saved-search/find', payload);
      setSavedSearches(result);
    })();
  }, [user]);

  async function saveSearch() {
    const { location, ...vc }: LocationSearchParams = JSON.parse(query.viewportCoordinates as string);
    const { propertyTypes, tags, ...restOfQuery } = query;

    if (restOfQuery.priceMax) {
      restOfQuery.priceMax = (Number(restOfQuery.priceMax) * 100).toFixed();
      restOfQuery.unit = 'cents';
    }

    const payload = {
      administrationMode: 'user',
      location: {
        vc,
        country,
        name: location?.label,
        city: location?.city,
      },
      searchInput: {
        ...restOfQuery,
        ...(propertyTypes ? { propertyTypes: JSON.parse(propertyTypes as string) } : {}),
        ...(tags ? { tags: JSON.parse(tags as string) } : {}),
        transactionType: 1,
        viewportCoordinates: vc,
      },
    };
    const result: SavedSearchAPIResult = await loadFromApi('saved-search/add', payload);
    setSavedSearches([...savedSearches, result]);
    setJustSaved(true);

    await track('User Saved Search Add');
  }

  const openGraphImages = getOGImagesFromCMSData(pageData, generalData);

  const searchResultsTopTabsList = [
    { view: 'grid', icon: faGrid2, text: t('grid') },
    {
      view: 'list',
      icon: faList,
      text: t('list'),
    },
    {
      view: 'map',
      icon: faMap,
      text: t('map'),
    },
    isMobile && {
      view: 'filters',
      icon: faSlidersH,
      text: t('filters'),
    },
  ].filter(Boolean) as searchResultsTopTabsListType[];

  const reorderedTabs = reorderTabs(searchResultsTopTabsList, whiteLabelConfig.searchNavigationViews);

  return (
    <Page header={{ data: headerData }} footer={{ data: footerData }} isGrid={currentView === 'grid'}>
      <SEO title={t('title', { companyName: whiteLabelConfig.name })} description={pageData.SEO.Description} images={openGraphImages} />
      <div className={currentView === 'grid' ? 'flex justify-center' : 'max-w-[1188px] mx-auto my-0 py-0 px-4 relative'}>
        <div
          className={
            currentView === 'grid'
              ? 'flex justify-center my-20 md:my-28 px-4 xs:px-8 w-full xs:w-auto'
              : 'my-20 md:my-28 min-[1200px]:flex min-[1200px]:justify-between'
          }
        >
          {!isMobile && (
            <SearchFilters>
              <Filters generalTexts={generalData} autoUpdate />
            </SearchFilters>
          )}
          <div className={currentView === 'grid' ? 'w-full xs:w-auto' : 'flex-auto max-w-[780px] min-[1200px]:max-w-none my-0 mx-auto'}>
            <SearchResultsTop>
              <SearchResultsTopHeadline>
                <Heading>{loading ? generalData.Misc.Loading : `${totalProperties} ${generalData.Misc.PropertyPlural.toLowerCase()}`}</Heading>
              </SearchResultsTopHeadline>
              <SearchResultsTopTabs tabsCount={reorderedTabs.length}>
                {reorderedTabs.map((tab) => (
                  <li key={tab.view} className={`${currentView === tab.view ? 'active' : ''}`} onClick={() => handeCurrentViewChange(tab.view)}>
                    <FontAwesomeIcon icon={tab.icon} />
                    <Heading>{tab.text}</Heading>
                  </li>
                ))}
              </SearchResultsTopTabs>
            </SearchResultsTop>
            <CurrentView
              searchResult={searchResult}
              generalData={generalData}
              pageData={pageData}
              currentView={currentView}
              query={query}
              gotoPage={gotoPage}
              updateCurrentView={updateCurrentView}
              searchResultType={searchResultType}
              setTotalProperties={handleSetTotalProperties}
              saveSearch={saveSearch}
              savedSearches={savedSearches}
              justSaved={justSaved}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Search;
