import { GetServerSidePropsContext } from 'next';
import { SearchCMSResult } from '../../types/cms/searchTypes';
import { LanguageKey } from '../../types/common';
import { ServerData } from '../../types/serverData';
import { loadAllDataFromCms } from '../../utils/http';
import { WhiteLabelPageRenderer } from 'components/WhiteLabel/WhiteLabelPageRenderer';
import Search from './Search';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const PAGE_NAME = 'search';

export interface SearchProps extends ServerData<SearchCMSResult> {}

const SearchPage = ({ headerData, footerData, generalData, pageData, languagePaths }: SearchProps) => {
  return (
    <WhiteLabelPageRenderer pageName={PAGE_NAME}>
      <Search pageData={pageData} headerData={headerData} footerData={footerData} generalData={generalData} languagePaths={languagePaths} />
    </WhiteLabelPageRenderer>
  );
};

export default SearchPage;

export async function getServerSideProps({ locale }: GetServerSidePropsContext) {
  const dataPromise = loadAllDataFromCms<SearchCMSResult>('search', locale as LanguageKey, ['SEO.OGImage']);
  const translationPromise = serverSideTranslations(locale as LanguageKey);

  const [data, translations] = await Promise.all([dataPromise, translationPromise]);

  return {
    props: {
      ...data,
      ...translations,
    },
  };
}
