import React from 'react';
import PropertyCard from './PropertyCard';
import PageSelect from 'components/PageSelect';
import { useRouter } from 'next/router';
import { getRoute } from 'utils/languages';
import { LanguageKey } from 'types/common';
import { SearchPropertiesResponse } from 'tenant-client';
import SearchPagePropertyCard from './SearchPagePropertyCard';

export type SearchPagePropertiesGridProps = {
  isClientSide?: boolean;
} & SearchPropertiesResponse;

const SearchPagePropertiesGrid = ({ properties, page, pageSize, totalPropertiesCount, isClientSide = false }: SearchPagePropertiesGridProps) => {
  const router = useRouter();
  const { asPath } = router;

  const gotoPage = (page: number) => {
    const { pathname, locale, query } = router;
    const { page: _, ...restQuery } = query;
    const newQuery = page === 1 ? restQuery : { ...query, page };
    router.push({ pathname: getRoute(pathname, locale as LanguageKey), query: newQuery }, undefined, { shallow: isClientSide });
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <div className="flex justify-center md:justify-start mb-14">
        <div className="grid-cols-1 xs:grid-cols-auto-fill-260 grid gap-7 xs:max-w-[640px] mdlg:max-w-[1000px] 2xl:max-w-[1200px] [@media(min-width:1760px)]:max-w-[1600px] [@media(min-width:2100px)]:max-w-[1700px] w-full">
          {properties.map((property) => (
            <SearchPagePropertyCard key={property.id} property={property} />
          ))}
        </div>
      </div>
      {totalPropertiesCount > pageSize && (
        <PageSelect
          page={page}
          totalResults={totalPropertiesCount}
          pageSize={pageSize}
          setPage={gotoPage}
          SEO={{ canonicalPath: asPath.split('?')[0] }}
        />
      )}
    </>
  );
};

export default SearchPagePropertiesGrid;
