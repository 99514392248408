import PageSelect from 'components/PageSelect';
import { useGetWindowSize } from 'hooks/useGetWindowSize';
import React, { Dispatch, SetStateAction } from 'react';
import Filters from 'sections/search/Filters';
import SearchResultList from 'sections/search/SearchResultList';
import { isBelowBreakpoint } from 'styles/theme';
import { SavedSearchAPIResult, SearchAPIResult } from 'types/api/searchTypes';
import { SearchCMSResult } from 'types/cms/searchTypes';
import { PAGE_SIZE, View } from '../Search';
import { ParsedUrlQuery } from 'querystring';
import { GeneralCMSResult } from 'types/cms/generalTypes';
import dynamic from 'next/dynamic';
import PropertiesGridWrapper from 'components/PropertiesGrid/PropertiesGridWrapper';
import SavedSearchBox from 'components/SavedSearch/SavedSearchBox';
import { SearchResultsLoading } from '../styles';
import LoadingIcon from 'components/LoadingIcon';

interface CurrentViewProps {
  searchResult: SearchAPIResult | undefined;
  generalData: GeneralCMSResult;
  pageData: SearchCMSResult;
  currentView: View;
  query: ParsedUrlQuery;
  saveSearch: () => Promise<void>;
  savedSearches: SavedSearchAPIResult[];
  justSaved: boolean;
  gotoPage: (page: number) => void;
  updateCurrentView: (view: View) => void;
  searchResultType: 'list' | 'grid' | 'map';
  setTotalProperties: (count: number) => void;
  loading: boolean;
}

const SearchResultMap = dynamic(() => import('../../../sections/search/SearchResultMap'));

const CurrentView = ({
  searchResult,
  generalData,
  pageData,
  currentView,
  query,
  gotoPage,
  updateCurrentView,
  searchResultType,
  setTotalProperties,
  saveSearch,
  savedSearches,
  justSaved,
  loading,
}: CurrentViewProps) => {
  const windowSize = useGetWindowSize();
  const isMobile = isBelowBreakpoint(windowSize?.width || 0, 'xl');
  const totalPropertyGroups = searchResult?.totalPropertyGroups || 0;
  const hiddenFiltersTab = currentView === 'filters' && !isMobile;
  const page = query.page ? parseInt(query.page as string) : 1;

  const renderListView = () => {
    if (loading) {
      return (
        <SearchResultsLoading>
          <LoadingIcon />
        </SearchResultsLoading>
      );
    }
    return (
      <>
        <SavedSearchBox
          query={query}
          saveSearch={saveSearch}
          savedSearches={savedSearches}
          justSaved={justSaved}
          fromCMS={pageData}
          generalTexts={generalData}
        />
        <SearchResultList data={searchResult} generalTexts={generalData} fromCMS={pageData} />
        {totalPropertyGroups > PAGE_SIZE && <PageSelect page={page} totalResults={totalPropertyGroups} pageSize={PAGE_SIZE} setPage={gotoPage} />}
      </>
    );
  };

  const renderGridView = () => {
    return (
      <PropertiesGridWrapper
        query={query}
        page={page}
        pageSize={24}
        setTotalProperties={setTotalProperties}
        saveSearch={saveSearch}
        savedSearches={savedSearches}
        justSaved={justSaved}
        pageData={pageData}
        generalData={generalData}
      />
    );
  };

  const renderMapView = () => {
    if (loading) {
      return (
        <SearchResultsLoading>
          <LoadingIcon />
        </SearchResultsLoading>
      );
    }
    return (
      <>
        <SavedSearchBox
          query={query}
          saveSearch={saveSearch}
          savedSearches={savedSearches}
          justSaved={justSaved}
          fromCMS={pageData}
          generalTexts={generalData}
        />
        <SearchResultMap data={searchResult} generalTexts={generalData} />
      </>
    );
  };

  const getCurrentView = () => {
    if (hiddenFiltersTab) {
      return renderListView();
    }
    switch (currentView) {
      case 'list':
        return renderListView();
      case 'grid':
        return renderGridView();
      case 'map':
        return renderMapView();
      case 'filters':
        return !hiddenFiltersTab ? <Filters generalTexts={generalData} onManualUpdate={() => updateCurrentView(searchResultType)} /> : null;
      default:
        return null;
    }
  };
  return <>{getCurrentView()}</>;
};

export default CurrentView;
