import { useApi } from 'providers/ApiProvider';
import { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { LocationFilter, PropertiesFilter } from 'tenant-client';
import { PagedAPIResult } from 'types/api/commonTypes';
import { PropertyGroupAPIResult } from 'types/api/propertyGroupTypes';
import { TENANT_API_BASE_URL } from 'utils/constants';
import { loadFromApi } from 'utils/http';

const DEFAULT_QUERY: PropertiesQuery = {};

export type PropertiesQuery = {
  ownerTypeAndIds?: string[];
  viewportCoordinates?: any;
  radius?: any;
  municipality?: string;
  zipCodes?: string | Array<string> | null;
  tags?: string | Array<string> | null;
  roomsMin?: string | number | null;
  upcomingProject?: string | boolean | null;
  postalCodeRange?: { start: string; end: string } | null;
} & Omit<PropertiesFilter, 'zipCodes' | 'tags' | 'upcomingProject' | 'roomsMin'>;

const createLocation = (viewportCoordinates: string | string[] | undefined, radius: string | string[] | undefined): LocationFilter => {
  if (!viewportCoordinates) {
    return { geoSpatial: {} };
  }

  const parsedCoordinates = JSON.parse(viewportCoordinates as string);
  if (!parsedCoordinates.center || !parsedCoordinates.center.coordinates) {
    return { geoSpatial: {} };
  }

  const { coordinates } = parsedCoordinates.center;
  const radiusMeters = radius ? Number.parseInt(radius as string, 10) * 1000 : undefined;

  return {
    geoSpatial: {
      latitude: coordinates[1],
      longitude: coordinates[0],
      radiusMeters: radiusMeters,
    },
  };
};

const createPropertiesSearchFilter = (query: PropertiesQuery): PropertiesFilter => {
  // We're not using spread operator due to PropertiesQuery and PropertiesFilter having different properties
  const { priceMax, roomsMin, sizeMin, propertyTypes, ownerTypeAndIds, availableFrom, petsAllowed, tags, handledBy, upcomingProject, zipCodes } =
    query;

  return {
    priceMax: normalizedNumber(priceMax, (price) => price * 100),
    roomsMin: normalizedNumber(roomsMin),
    sizeMin: normalizedNumber(sizeMin),
    propertyTypes: propertyTypes ? JSON.parse(String(propertyTypes)) : undefined,
    ownerTypeAndIds,
    availableFrom,
    petsAllowed: normalizedBoolean(petsAllowed),
    tags: normalizeStringArray(tags),
    handledBy,
    upcomingProject: normalizedBoolean(upcomingProject),
    zipCodes: Array.from(new Set([...normalizeZipCodes(zipCodes)])),
    location: createLocation(query.viewportCoordinates, query.radius),
  };
};

const normalizedNumber = (number: string | number | null | undefined, transformer?: (value: number) => number): number | undefined => {
  if (number != null) {
    const normalized = Number(number);
    return transformer ? transformer(normalized) : normalized;
  }
  return undefined;
};

const normalizedBoolean = (upcomingProject: string | boolean | null | undefined) => {
  return upcomingProject !== undefined
    ? typeof upcomingProject === 'string'
      ? upcomingProject.toLowerCase() === 'true'
      : !!upcomingProject
    : undefined;
};

// This fetches the filtered properties from the old API, and then uses a query based on that result to fetch the same properties again...
// This is a temporary solution until similar endpoints have been added in the new 'backend' API
const getPropertiesByMunicipalityAndTags = async (
  municipality: string,
  tags: string[],
): Promise<PagedAPIResult<PropertyGroupAPIResult> | undefined> => {
  if (municipality) {
    return await loadFromApi('property-group/find-by-municipality-and-tags', { municipality });
  }
  if (tags.length > 0) {
    return await loadFromApi('property-group/find-by-tags', { tags });
  }
  return undefined;
};

const normalizeStringArray = (input: string | string[] | null | undefined): string[] => {
  if (typeof input === 'string') {
    try {
      return JSON.parse(input);
    } catch {
      return [input];
    }
  }
  return input || [];
};

// The string format for zipCodes may originate from CSVs within viewportCoordinates or other sources.
const normalizeZipCodes = (zipCodes: string | string[] | null | undefined): string[] => {
  return Array.isArray(zipCodes) ? zipCodes : typeof zipCodes === 'string' ? zipCodes.split(',').map((zip) => zip.trim()) : [];
};

export async function getSlugPropertiesServerSide({ tags, postalCodeRange }: PropertiesQuery, page: number, pageSize: number) {
  if (!postalCodeRange && (!tags || tags.length === 0)) {
    return {
      page,
      pageSize,
      totalPropertiesCount: 0,
      properties: [],
    };
  }
  const normalizedTags = normalizeStringArray(tags);
  const query: PropertiesQuery = {
    tags: normalizedTags,
    postalCodeRange,
    location: {
      geoSpatial: {},
    },
  };

  return await getPropertiesServerSide(query, page, pageSize);
}

export async function getPropertiesServerSide(query: PropertiesQuery = DEFAULT_QUERY, page: number, pageSize: number) {
  const response = await fetch(`${TENANT_API_BASE_URL}/properties/search?page=${page}&pageSize=${pageSize}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(query),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return await response.json();
}

export const usePropertiesSearch = (query: PropertiesQuery = DEFAULT_QUERY, page: number, pageSize: number) => {
  const { api } = useApi();

  const propertiesFilter = useMemo(() => {
    return createPropertiesSearchFilter(query);
  }, [query]);

  return useAsync(async () => {
    return await api.searchPropertiesPropertiesSearchPost(propertiesFilter, page, pageSize);
  }, [propertiesFilter, page, pageSize]);
};
