import LoadingIcon from 'components/LoadingIcon';
import { usePropertiesSearch } from 'hooks/usePropertiesSearch';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import { useMemo } from 'react';
import SearchPagePropertiesGrid, { SearchPagePropertiesGridProps } from './SearchPagePropertiesGrid';
import SavedSearchBox from 'components/SavedSearch/SavedSearchBox';
import { ParsedUrlQuery } from 'querystring';
import { useTranslation } from 'next-i18next';

type PropertiesGridWrapperProps = {
  query: ParsedUrlQuery;
  saveSearch: () => Promise<void>;
  savedSearches: any[];
  justSaved: boolean;
  pageData: any;
  generalData: any;
  setTotalProperties: (count: number) => void;
} & Omit<SearchPagePropertiesGridProps, 'properties' | 'totalPropertiesCount'>;

const PropertiesGridWrapper = ({
  query,
  page,
  pageSize = 20,
  setTotalProperties,
  saveSearch,
  savedSearches,
  justSaved,
  pageData,
  generalData,
}: PropertiesGridWrapperProps) => {
  const { companyIds } = useWhiteLabel();
  const updatedQuery = useMemo(
    () => ({ ...query, ownerTypeAndIds: [...(query?.ownerTypeAndIds || []), ...companyIds?.map((id) => `c.${id}`)] }),
    [query, companyIds],
  );
  const { result, loading } = usePropertiesSearch(updatedQuery, page, pageSize);
  const { t } = useTranslation('search');

  if (loading || !result) {
    return (
      <div className="flex justify-center md:justify-start mb-14 min-h-screen">
        <div className="relative grid-cols-1 xs:grid-cols-auto-fill-260 grid gap-7 xs:max-w-[640px] mdlg:max-w-[1000px] 2xl:max-w-[1200px] [@media(min-width:1760px)]:max-w-[1600px] [@media(min-width:2100px)]:max-w-[1700px] w-full">
          <div className="absolute top-[67px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <LoadingIcon />
          </div>
          <div className="flex relative flex-col h-[420px] xs:max-w-[260px] w-full" />
        </div>
      </div>
    );
  }

  if (result.totalPropertiesCount === 0) {
    return (
      <>
        <SavedSearchBox
          query={query}
          saveSearch={saveSearch}
          savedSearches={savedSearches}
          justSaved={justSaved}
          fromCMS={pageData}
          generalTexts={generalData}
        />
        <div className="flex justify-center md:justify-start mb-14 min-h-screen">
          <div className="relative grid-cols-1 xs:grid-cols-auto-fill-260 grid gap-7 xs:max-w-[640px] mdlg:max-w-[1000px] 2xl:max-w-[1200px] [@media(min-width:1760px)]:max-w-[1600px] [@media(min-width:2100px)]:max-w-[1700px] w-full">
            <div className="absolute top-[67px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center text-center w-[300px] md:w-[600px]">
              <h3 className="mb-[18px] text-base md:text-xl font-semibold">{t('noPropertiesFound')}</h3>
              <span className="text-base md:text-base">{t('tryAdjustingSearch')}</span>
            </div>
            <div className="flex relative flex-col h-[420px] xs:max-w-[260px] w-full" />
          </div>
        </div>
      </>
    );
  }

  const { properties, totalPropertiesCount } = result;

  setTotalProperties(totalPropertiesCount || 0);

  return (
    <>
      <SavedSearchBox
        query={query}
        saveSearch={saveSearch}
        savedSearches={savedSearches}
        justSaved={justSaved}
        fromCMS={pageData}
        generalTexts={generalData}
      />
      <SearchPagePropertiesGrid
        properties={properties}
        page={page}
        pageSize={pageSize}
        totalPropertiesCount={totalPropertiesCount}
        isClientSide={true}
      />
    </>
  );
};

export default PropertiesGridWrapper;
